<template>
    <div class="base-page">
        <div class="query-box">
            <el-form :inline="true" :model="query_form"  ref="query_form" class="demo-form-inline">
                <el-form-item label="月份范围：" prop="date_between">

                    <el-date-picker
                            size="mini"
                            v-model="query_form.month_between"
                            type="monthrange"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始月份"
                            end-placeholder="结束月份"
                            @change="monthChang"
                    >
                    </el-date-picker>

                </el-form-item>


                <el-form-item label="查询对象：" prop="type" style="margin-left: 15px">
                    <el-select v-model="query_form.type" placeholder="请选择"  size="mini" style="width: 100px" >
                        <el-option
                                v-for="item in type_options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <template v-if="query_form.type==1">
                    <el-form-item  prop="order_channel" >
                        <el-select v-model="query_form.select_build_id" placeholder="请选择楼宇"  size="mini" style="width: 200px">
                            <el-option
                                    key="0"
                                    label="全部"
                                    value="0">
                            </el-option>
                            <el-option
                                    v-for="item in query_form.buildArr"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </template>

                <template v-if="query_form.type==2">
                    <el-form-item  prop="order_channel">
                        <el-select v-model="query_form.select_unit_id" placeholder="请选择单元"  size="mini" style="width: 200px">
                            <el-option
                                    key="0"
                                    label="全部"
                                    value="0">
                            </el-option>
                            <el-option
                                    v-for="item in query_form.unitArr"
                                    :key="item.unit_id"
                                    :label="item.all_name"
                                    :value="item.unit_id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </template>

                <el-form-item>
                    <el-button size="mini" type="primary" @click="query">查询</el-button>
                </el-form-item>



            </el-form>


        </div >


        <div class="base-body" id="base-body">

            <div class="base-table" >

                <el-table
                        :data="dataList"
                        border
                        :height="tableHeight"
                        v-loading="loading"
                        :style="{width:tableWidth}"

                        ref="table"

                >
                    <template v-if="dataList.length>0">

                        <el-table-column label="楼宇/单元" prop="name" fixed="left" width="100">
                        </el-table-column>

                        <el-table-column label="应收数 (费用期间：选择的月份范围) " >
                            <el-table-column
                                    v-for="item in ys_columns"
                                    :key="item.prop"
                                    :prop="item.prop"
                                    :label="item.label"
                                    :width="item.width"
                                    :formatter="item.formatter"
                                    :fixed = "item.fixed"
                            >
                            </el-table-column>
                        </el-table-column>

                        <el-table-column label="实收数 (收费周期：选择的月份范围，费用期间：选择的月份范围) " >
                            <el-table-column
                                    v-for="item in ss_columns"
                                    :key="item.prop"
                                    :prop="item.prop"
                                    :label="item.label"
                                    :width="item.width"
                                    :formatter="item.formatter"
                                    :fixed = "item.fixed"
                            >
                            </el-table-column>
                        </el-table-column>

                        <el-table-column label="分摊到本期 (收费周期：选择的范围内每月之前分摊到本期费用之和，费用期间：选择的月份范围) " >
                            <el-table-column
                                    v-for="item in ftbq_columns"
                                    :key="item.prop"
                                    :prop="item.prop"
                                    :label="item.label"
                                    :width="item.width"
                                    :formatter="item.formatter"
                                    :fixed = "item.fixed"
                            >
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="收费率  ( 收费率 =（分摊到本期+实收)/应收 )" >
                            <el-table-column
                                    v-for="item in sfl_columns"
                                    :key="item.prop"
                                    :prop="item.prop"
                                    :label="item.label"
                                    :width="item.width"
                                    :formatter="item.formatter"
                                    :fixed = "item.fixed"
                            >
                            </el-table-column>
                        </el-table-column>

                        <el-table-column label="排名" prop="pm" fixed="right">

                        </el-table-column>
                    </template>

                </el-table>
            </div>
        </div>


    </div>




</template>

<script>

    export default {
        name: "main_page",
        props:['upPageParams'],
        components:{

        },
        data() {
            return {

                loading:false,

                query_form:{

                    //查询对象
                    type:'1',//默认按楼宇
                    buildArr:[],//楼宇数据
                    unitArr:[],//单元数据
                    select_build_id:"0",//选择的楼宇，默认全部
                    select_unit_id:"0",//选择的单元，默认全部

                    //费用期间
                    month_between:[],
                    start_month:'',
                    end_month:''

                },


                type_options: [ {
                    value: '1',
                    label: '按楼宇'
                }, {
                    value: '2',
                    label: '按单元'
                }],


                tableHeight: 0,
                tableWidth:0,
                ys_columns:[],
                ss_columns:[],
                ftbq_columns:[],
                sfl_columns:[],


                dataList:[],


            }
        },
        created() {
            this.getBuildList()
            this.getUnitList()
        },
        mounted() {

            this.$nextTick(function() {
                this.tableHeight = document.getElementById("base-body").offsetHeight;
                this.tableWidth= document.getElementById("base-body").offsetWidth + 'px';
            })
        },
        methods:{

            //获取楼宇
            getBuildList(){
                let params = {
                    residence_id:this.upPageParams.commiunity.id
                }
                this._getApi('/wy/residenceBuild/getList',params).then((res)=>{
                    if (res.code ==1){

                        this.query_form.buildArr = res.data.list

                    }

                })
            },
            //获取单元列表
            getUnitList(){
                let params = {
                    residence_id:this.upPageParams.commiunity.id
                }
                this._getApi('/wy/residenceBuildUnit/getBuildUnitListByResidence',params).then((res)=>{
                    if (res.code ==1){
                        this.query_form.unitArr = res.data
                    }
                })
            },


            //费用期间变化
            monthChang(){
                if (this.query_form.month_between){
                    this.query_form.start_month = this.dayjs(this.query_form.month_between[0]).format("YYYY-MM")
                    this.query_form.end_month = this.dayjs(this.query_form.month_between[1]).format("YYYY-MM")
                }else {
                    this.query_form.start_month = ''
                    this.query_form.end_month  = ''
                }

            },


            //查询
            query(){
                if (this.query_form.start_month=='' || this.query_form.end_month==''){
                    this.$alert('请选择月份范围', '提示', {
                        confirmButtonText: '确定',
                        type: 'warning'
                    }).then(() => {

                    }).catch(() => {

                    });
                    return false
                }
                let params = {
                    residence_id:this.upPageParams.commiunity.id,
                    type:this.query_form.type,

                   month_start:this.query_form.start_month,
                   month_end:this.query_form.end_month,
                }
                //楼栋或者单元id
                if (params.type==1){
                    params.id = this.query_form.select_build_id
                }else {
                    params.id = this.query_form.select_unit_id
                }


                this.loading = true
                this._postApi('/wy/data/chargeRate/getList', params).then((res) => {
                    this.loading = false

                    if (res.code == 1) {

                        this.ys_columns = [];
                        this.ss_columns = [];
                        this.ftbq_columns = [];
                        this.sfl_columns = [];
                        this.dataList = [];

                        if (res.data.list.length > 0){
                            let ys_head = res.data.ys_head
                            let ss_head = res.data.ss_head
                            let ftbq_head = res.data.ft_head
                            let sfl_head = res.data.sfl_head


                            for (let i=0;i<ys_head.length;i++){
                                this.ys_columns.push({
                                    label:ys_head[i]['title'],
                                    prop:ys_head[i]['field'],
                                    width:120
                                })
                            }


                            for (let i=0;i<ss_head.length;i++){
                                this.ss_columns.push({
                                    label:ss_head[i]['title'],
                                    prop:ss_head[i]['field'],
                                    width:120
                                })
                            }



                            for (let i=0;i<ftbq_head.length;i++){
                                this.ftbq_columns.push({
                                    label:ftbq_head[i]['title'],
                                    prop:ftbq_head[i]['field'],
                                    width:120
                                })
                            }


                            for (let i=0;i<sfl_head.length;i++){
                                this.sfl_columns.push({
                                    label:sfl_head[i]['title'],
                                    prop:sfl_head[i]['field'],
                                    width:120
                                })
                            }

                            this.dataList = res.data.list

                            //解决fixed 错位
                            this.$nextTick(function() {
                                this.$refs.table.doLayout()
                            })
                        }else {
                            this.dataList = res.data.list
                        }

                    } else {

                        this.$confirm(res.msg, '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {

                        }).catch(() => {

                        });
                    }
                }).catch((err) => {
                    this.loading = false
                })


            },


            closeEvent(e) {
                if (e.type == 'close') {
                    this.dialog_order_info.open = false;
                }
            },

        }
    }
</script>

<style scoped>
    .query-box{
        padding: 15px;
        display: flex;flex-direction: row;
    }
    .base-page{
        width: 100%;height: 100%;
        display: flex;flex-direction: column;
    }

    .base-body{
        flex: 1;width: 100%;
    }

    .base-table{
        padding: 0 15px;
        border-radius: 5px;
        background: #ffffff;
        height: 100%;
        width: 100%;



    }
    .base-footer{
        text-align: right;
        padding: 5px;
    }

    .demo-table-expand {
        font-size: 0;
    }
    .demo-table-expand label {
        width: 90px;
        /*color: #99a9bf;*/
    }
    .demo-table-expand .el-form-item {
        margin-right: 0;
        margin-bottom: 0;
        width: 50%;
    }

</style>
